exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-act-js": () => import("./../../../src/pages/act.js" /* webpackChunkName: "component---src-pages-act-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-connected-js": () => import("./../../../src/pages/connected.js" /* webpackChunkName: "component---src-pages-connected-js" */),
  "component---src-pages-consent-js": () => import("./../../../src/pages/consent.js" /* webpackChunkName: "component---src-pages-consent-js" */),
  "component---src-pages-consented-js": () => import("./../../../src/pages/consented.js" /* webpackChunkName: "component---src-pages-consented-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-delete-js": () => import("./../../../src/pages/delete.js" /* webpackChunkName: "component---src-pages-delete-js" */),
  "component---src-pages-device-registered-js": () => import("./../../../src/pages/device-registered.js" /* webpackChunkName: "component---src-pages-device-registered-js" */),
  "component---src-pages-device-security-js": () => import("./../../../src/pages/device-security.js" /* webpackChunkName: "component---src-pages-device-security-js" */),
  "component---src-pages-devices-js": () => import("./../../../src/pages/devices.js" /* webpackChunkName: "component---src-pages-devices-js" */),
  "component---src-pages-end-js": () => import("./../../../src/pages/end.js" /* webpackChunkName: "component---src-pages-end-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiate-js": () => import("./../../../src/pages/initiate.js" /* webpackChunkName: "component---src-pages-initiate-js" */),
  "component---src-pages-installation-js": () => import("./../../../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-invite-only-js": () => import("./../../../src/pages/invite-only.js" /* webpackChunkName: "component---src-pages-invite-only-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-openbanking-faqs-js": () => import("./../../../src/pages/openbanking-faqs.js" /* webpackChunkName: "component---src-pages-openbanking-faqs-js" */),
  "component---src-pages-preferences-js": () => import("./../../../src/pages/preferences.js" /* webpackChunkName: "component---src-pages-preferences-js" */),
  "component---src-pages-provider-js": () => import("./../../../src/pages/provider.js" /* webpackChunkName: "component---src-pages-provider-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-bank-calendar-js": () => import("./../../../src/pages/request-bank-calendar.js" /* webpackChunkName: "component---src-pages-request-bank-calendar-js" */),
  "component---src-pages-request-received-js": () => import("./../../../src/pages/request-received.js" /* webpackChunkName: "component---src-pages-request-received-js" */),
  "component---src-pages-setting-js": () => import("./../../../src/pages/setting.js" /* webpackChunkName: "component---src-pages-setting-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */)
}

